import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import { PageHeader, Services } from "../components/sections"
import {
  HelpItsecurity,
  antivirus,
  cybersecurity,
  emailsecurity,
  endpoint,
  itriskassessments,
  mobilesercurity,
  securityconsulting,
} from "../images/itsecurity"
import {
  BlackSection,
  DetailSection,
  IntroSection,
  OfferSection,
  Points,
} from "../components/structure"
import { AboutCard } from "../components/elements"

const weOffer = [
  {
    id: 1,
    src: itriskassessments,
    title: "IT Risk Assessments",
    content:
      "Your business cyber security starts with an IT risk assessment. Our qualified and experienced team will identify all the IT security risks threatening your business, determine a custom strategy that is suited to your needs, review your current risk management methods, and track your progress as time goes on.Improve your network performance and user experience with our managed SD-WAN, hybrid network, and MPLS. The hybrid approach utilises both SD-WAN and MPLS and integrates cost-effective broadband to reap the benefits of both networking solutions, including reliability and reduced operational costs.",
    order: 0,
  },
  {
    id: 2,
    src: antivirus,
    title: "Network Security Services",
    content:
      "Anti-virus products were once sufficient for protecting yourself, but that is no longer the case. To keep your business completely safe, you need a firewall solution with integrated features designed to keep out even the most complex attacks. Our network security services provide the solutions that you need to protect your business.",
    order: 1,
  },
  {
    id: 3,
    src: endpoint,
    title: "Endpoint Protection",
    content:
      "Endpoints are common targets, so it’s well-worth defending them with endpoint protection. We offer premium endpoint protection solutions to protect the devices that your business relies on to operate.",
    order: 0,
  },
  {
    id: 4,
    src: emailsecurity,
    title: "Email Security",
    content:
      "Our email security solutions will protect your business from spam, emails containing viruses, phishing scams, and more. Using only the best products and methods, we will detect viruses and spam and quarantine or discard all threats so your business can continue to thrive without stressful and annoying email attacks.",
    order: 1,
  },
  {
    id: 5,
    src: mobilesercurity,
    title: "Mobile Security",
    content:
      "Like other workstations, mobile devices deal with sensitive data that is crucial to your business. That’s why we offer advanced mobile security solutions, ensuring that your business devices are protected against foreign attacks.",
    order: 0,
  },
  {
    id: 6,
    src: securityconsulting,
    title: "Security Consulting",
    content:
      "Have questions about IT security? We offer security consulting, using our years of experience and expertise to help you understand the security issues that might be affecting your business and what moves you can take to protect yourself. ",
    order: 1,
  },
]

const points = [
  {
    id: 1,
    point: "Assessing your current IT security stance",
  },
  {
    id: 2,
    point: "Customising the right defence strategy for your business",
  },
  {
    id: 3,
    point:
      "Protecting your network and devices from viruses, malware, and hacking",
  },
  {
    id: 4,
    point: "Keeping your email system free of spam, viruses, and scams",
  },
  {
    id: 5,
    point:
      "Answering all your security questions and providing ongoing technical support",
  },
]
export default function CyberSecurity() {
  const data = useStaticQuery(graphql`
    query {
      index: file(relativePath: { eq: "headers/cyberProduct.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const index = [
    data.index.childImageSharp.fluid,
    `linear-gradient(0deg,
      rgba(0, 0, 0, 0.30),
      rgba(0, 0, 0, 0.30))`,
  ].reverse()
  return (
    <Layout>
      <SEO title="Cyber Security" />
      <PageHeader
        fluid={index}
        title="Can your business afford a cyberattack?"
        catchy="Cyber Security / Internet Security"
        subtitle="Protect your business now"
      />
      <IntroSection
        title="It Security"
        content="No business can afford to be careless when it comes to IT security. Our premium technology and proven strategies will keep you protected in the digital space so your business can continue to flourish in the physical world. From risk assessments to endpoint protection, we know exactly what cyber steps to take to keep your business secure."
        src={cybersecurity}
      />
      <BlackSection title="Book now and keep your business safe" />
      <IntroSection
        title="How we will help you?"
        content="IT security is something your business must take seriously. Without taking the proper precautions, your company could crumble at the hands of viruses, malware, hacking, scams, and other cyberattacks that threaten the functioning of your organisation. We will help you evaluate how secure your technical infrastructure is and what your vulnerabilities are. Then we’ll determine what you need to do to protect yourself from attacks. Using the most advanced products and strategies, we provide risk assessments, network security services, endpoint and mobile protection, and email security to defend your business."
        src={HelpItsecurity}
      />
      <OfferSection title="What we offer:">
        {weOffer.map((item, id) => (
          <AboutCard
            key={item.id}
            order={item.order}
            src={item.src}
            to={item.to}
            title={item.title}
            content={item.content}
          />
        ))}
      </OfferSection>
      <DetailSection
        paragraph1="Prevention is your best line of defence when it comes to cyber security. If you don’t take the appropriate measures to protect your network and devices, you open yourself up to cyberattacks that could be devastating to your business. Avoid the stress by utilising our IT security services that will benefit your business by:"
        paragraph2="Cyber and data security are vital to your business. An attack could cause major stress for you and your employees, so don’t risk it. Get in touch today to enquire about our IT security services and how they can keep your business safe."
      >
        {points.map((item, id) => (
          <Points key={item.id}>{item.point}</Points>
        ))}
      </DetailSection>
      <Services />
      <BlackSection title="Protect your business now" />
    </Layout>
  )
}
